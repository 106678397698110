// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-benefits-index-tsx": () => import("./../../../src/pages/benefits/index.tsx" /* webpackChunkName: "component---src-pages-benefits-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solution-index-tsx": () => import("./../../../src/pages/solution/index.tsx" /* webpackChunkName: "component---src-pages-solution-index-tsx" */)
}

